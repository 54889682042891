import React, {useEffect} from 'react'
import './Homehero.scss'
import 'bootstrap'
import { FaInstagram , FaFacebookF ,FaPinterestP} from 'react-icons/fa'
import { Link } from 'react-router-dom'

import first1 from '../images/Hero-one-min.webp'
import first2 from '../images/first1.jpg'
import first3 from '../images/first2.jpg'

import second1 from '../images/Hero-two-min.webp'
import second2 from '../images/second2_1-1.webp'

import third1 from '../images/third01.webp'
import third2 from '../images/third02.webp'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import gsap from 'gsap/all'

function Homehero() {

    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <div>
            <div id="hero">
                <div className="inner-hero">
                <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="false">
                    <div className="container-fluid">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" >
                                <span>01</span>
                                <span className='indi-content'>Modern Residential<br/>Living</span>
                                <span className='car-dash'></span>
                            </button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2">
                                <span>02</span>
                                <span className='indi-content'>Reality beyond<br/>Imagination</span>
                                <span className='car-dash'></span>
                            </button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3">
                                <span>03</span>
                                <span className='indi-content'>Tattoo Parlor<br/>Ahmedabad</span>
                                <span className='car-dash'></span>
                            </button>
                        </div>
                    </div>
                    
                    <div className="carousel-inner">
                        <div className="carousel-item active first">
                            <div className="car-img-wrapper">
                                <Carousel responsive={responsive} autoPlay={true} arrows={false} infinite={true}>
                                    <img className='car-img first1' src={first1} alt="" />
                                    <img className='car-img first2' src={first2} alt="" />
                                    <img className='car-img first3' src={first3} alt="" />
                                </Carousel>
                            </div>
                            <div className="carousel-caption d-block d-md-block">
                                <div className="caption-inner">
                                    
                                    <span className="number"><span>01</span>/03</span>
                                    <div className="caption-content">
                                        <div className="line">
                                            <h2>State-of-art</h2>
                                        </div>
                                        <div className="line">
                                            <h2>Design</h2>
                                        </div>
                                        <Link to="/projects/1" className='caption-link'>View Project</Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item second first">
                            <div className="car-img-wrapper">
                                <Carousel responsive={responsive} autoPlay={true} arrows={false} infinite={true}>
                                    <img className='car-img first1' src={second1} alt="" />
                                    <img className='car-img first2' src={second2} alt="" />
                                </Carousel>
                            </div>
                            <div className="carousel-caption d-block d-md-block">
                                <div className="caption-inner">
                                    <span className="number" name="num"><span>02</span>/03</span>
                                    <div className="caption-content">
                                        <div className="line">
                                            <h2>Playful, Modern</h2>
                                        </div>
                                        <div className="line">
                                            <h2>and Inspirational</h2>
                                        </div>
                                        <Link to="/projects/2" className='caption-link'>View Project</Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item third first">
                            <div className="car-img-wrapper">
                                <Carousel responsive={responsive} autoPlay={true} arrows={false} infinite={true}>
                                    <img className='car-img third1' src={third1} alt="" />
                                    <img className='car-img third2' src={third2} alt="" />
                                </Carousel>
                            </div>
                                
                            <div className="carousel-caption d-block d-md-block">
                                <div className="caption-inner">
                                    <span className="number"><span>03</span>/03</span>
                                    <div className="caption-content">
                                        <div className="line">
                                            <h2>International style</h2>
                                        </div>
                                        {/* <div className="line">
                                            <h2>with utter</h2>
                                        </div> */}
                                        <Link to="/projects/3" className='caption-link'>View Project</Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
        
                    </div>

                    <div className="social-share">
                        <FaInstagram/>
                        <FaFacebookF/>
                        <FaPinterestP/>
                    </div>

                    <div className="animated-dash">
                
                    </div>
            </div>
        </div>
           
        </div>
    )

    
}

export default Homehero
