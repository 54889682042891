import React from 'react'
import Header from '../Components/Header'
function PageNotFound() {
  const styles= {
    width: '100%',
    height: '100vh',
    textAlign: 'center'
  }
  return (
    <div className='page-not-found' style={styles}>
      <Header/>
      <h1 style={{position: 'absolute', left: '50%', top: '50%', fontSize: '100px', transform: 'translate(-50%, -50%)'}}>404<span style={{fontSize: '14px'}}>Page Not Found</span></h1>
    </div>
  )
}

export default PageNotFound